import { i18n } from '@/i18n'

const { t } = i18n.global

export enum ProfileTypeChoices {
  B1 = 'B1',
  B2 = 'B2',
  B3 = 'B3',
  B4 = 'B4',
}

export const GET_PROFILE_TYPES = (t) => [
  {
    value: ProfileTypeChoices.B3,
    label: t('Medical institution'),
    menuTitle: t('Medical facility data'),
    facilityAddTitle: t('Add medical facility'),
    facilityAddBtn: t('Add medical facility'),
    facilityAddText: t(
      'To start using the service, you need to add a medical facility.'
    ),
  },
  {
    value: ProfileTypeChoices.B2,
    label: t(
      'Doctor with a private practice (I will add the medical facility myself)'
    ),
    menuTitle: t('Medical facility data'),
    facilityAddTitle: t('Add medical facility'),
    facilityAddBtn: t('Add medical facility'),
    facilityAddText: t(
      'To start using the service, you need to add a medical facility.'
    ),
  },
  {
    value: ProfileTypeChoices.B1,
    label: t(
      'A doctor of a medical institution that is already represented on the platform'
    ),
  },
  {
    value: ProfileTypeChoices.B4,
    label: t('Branch of a medical institution'),
    menuTitle: t('Branch'),
    facilityAddTitle: t('Add branch'),
    facilityAddBtn: t('Add branch'),
    facilityAddText: t(
      'To start using the service, you need to add a  branch of medical facility.'
    ),
  },
]

export type NotificationFlag = {
  id: number
  label: string
  display: string
}

export interface Notification {
  id: string
  flags: NotificationFlag[]
  data: {
    icon: string
    body: string
    title: string
  }
}

export interface NotificationGroup {
  date: string
  notifications: Notification[]
}

export enum NOTIFICATIONS_FLAGS {
  UNREAD = 73232,
  READ = 73236,
  MODERATION = 10,
  RECORDS = 11,
  SERVICES = 12,
  EMPLOYMENT = 13,
  REVIEWS = 14,
  COMPLAINTS = 15,
  OTHERS = 16,
}

export const NotificationsTypesTitles = {
  [NOTIFICATIONS_FLAGS.RECORDS]: t('Appointment'),
  [NOTIFICATIONS_FLAGS.MODERATION]: t('Moderation'),
  [NOTIFICATIONS_FLAGS.SERVICES]: t('Services'),
  [NOTIFICATIONS_FLAGS.EMPLOYMENT]: t('Employment'),
  [NOTIFICATIONS_FLAGS.REVIEWS]: t('Reviews'),
  [NOTIFICATIONS_FLAGS.COMPLAINTS]: t('Complaints'),
  [NOTIFICATIONS_FLAGS.OTHERS]: t('Other'),
}

export const NotificationsTypes = [
  {
    id: NOTIFICATIONS_FLAGS.RECORDS,
    title: NotificationsTypesTitles[NOTIFICATIONS_FLAGS.RECORDS],
  },
  {
    id: NOTIFICATIONS_FLAGS.MODERATION,
    title: NotificationsTypesTitles[NOTIFICATIONS_FLAGS.MODERATION],
  },
  {
    id: NOTIFICATIONS_FLAGS.SERVICES,
    title: NotificationsTypesTitles[NOTIFICATIONS_FLAGS.SERVICES],
  },
  {
    id: NOTIFICATIONS_FLAGS.EMPLOYMENT,
    title: NotificationsTypesTitles[NOTIFICATIONS_FLAGS.EMPLOYMENT],
  },
  {
    id: NOTIFICATIONS_FLAGS.REVIEWS,
    title: NotificationsTypesTitles[NOTIFICATIONS_FLAGS.REVIEWS],
  },
  {
    id: NOTIFICATIONS_FLAGS.COMPLAINTS,
    title: NotificationsTypesTitles[NOTIFICATIONS_FLAGS.COMPLAINTS],
  },
  {
    id: NOTIFICATIONS_FLAGS.OTHERS,
    title: NotificationsTypesTitles[NOTIFICATIONS_FLAGS.OTHERS],
  },
]
